import React from 'react';

function Home() {
  return (
    <div className='home'>
      <div className='aboutMore'>
        <div className='logo'>
            <img src='/images/gimt_logo_new.svg' alt='Logo'/>
        </div>
        <div className='slogan'>
        Бид мэдээллийн урсгалыг автоматжуулах замаар бизнесийн өсөлтийг дэмжинэ
        </div>
        <div className='small_logos'>
            <div className='section' style={{paddingLeft: '77px'}}>
                <img src='/images/amarcv_small.svg' alt='AmarCV'/>
            </div>
            <div className='section'>
                <img src='/images/amarhr_small.svg' alt='AmarHR' style={{marginTop: '-8px'}} />
            </div>
            <div className='section'>
                <div className='loader'></div>
                <div className='loader_text'>next SAAS product</div>
            </div>
        </div>
        <div className='contactUs'>
            Бидэнтэй холбогдох: <span>hello@gitm.mn</span>
        </div>
        <div className='partners'>
            <a href='https://datacom.mn/' target='_blank' rel="noreferrer">
                <div className='singlePartner'>
                    <div className='type'>
                        Технологи & Аюулгүй ажиллагааны хамтрагч
                    </div>
                    <div className='logo'>
                        <img src='/images/datacom.svg' alt='Datacom'/>
                    </div>
                    <div className='name'>
                        DATACOM
                    </div>
                </div>
            </a>

            {/* <a href='https://caml.mn/' target='_blank' rel="noreferrer">
                <div className='singlePartner'>
                    <div className='type'>
                        Business Partner
                    </div>
                    <div className='logo'>
                        <img src='/images/caml.png' alt='CAML'/>
                    </div>
                    <div className='name'>
                        CAML
                    </div>
                </div>
            </a> */}
        </div>
      </div>
    </div>
  );
}

export default Home;
